.paperBase {
  border-radius: 0px;
}

.paper {
  composes: paperBase;
}

.paperImportant {
  composes: paperBase;
    /* important feature distinction removed, we leave the styles in case they come back */
}

.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-size: 16px;
}
.titleImportant {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-size: 20px;
}

.link {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}
.linkImportant {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.grid {
  display: grid;
  grid-auto-rows: max-content;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
