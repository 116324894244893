.table {
  table-layout: fixed;
  @media (max-width: $mantine-breakpoint-xs) {
    tbody {
      tr {
        td {
          border: none;
          --text-fz: var(--mantine-font-size-xs);
        }
      }
    }
  }
}

.filterInput {
  border: 0px;
}

.filterIcon {
  color: var(--mantine-color-texts);
}

.filterRoot {
  border-radius: 4px;
  border: 1px solid var(--mantine-color-greyBorder);
}