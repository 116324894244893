.Balance_card__ra1dz {
  overflow: visible;
}

.Balance_chartBox__ZM0aF {
  height: 120px;
  max-width: 240px;
}

.StakingRewards_contentDiv__fS50W {
  position: relative;
  height: 305px;
}

.StakingRewards_chartDiv__cbcic {
  height: 212px;
  margin-top: 10px;
}

.Assets_table__7E_f4 {
  table-layout: fixed;
}
  @media (max-width: 36em) {
        .Assets_table__7E_f4 tbody tr td {
          border: none;
          --text-fz: var(--mantine-font-size-xs);
        }
  }

.Assets_filterInput__oApNI {
  border: 0px;
}

.Assets_filterIcon__E9Aik {
  color: var(--mantine-color-texts);
}

.Assets_filterRoot__4MJ_9 {
  border-radius: 4px;
  border: 1px solid var(--mantine-color-greyBorder);
}
.Announcement_paperBase__4N1R6 {
  border-radius: 0px;
}

.Announcement_paper__Iqn6k {
}

.Announcement_paperImportant__LcZGC {
    /* important feature distinction removed, we leave the styles in case they come back */
}

.Announcement_title__xTGIB {
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Announcement_title__xTGIB {
  color: var(--mantine-color-white);
}

.Announcement_title__xTGIB {
  font-size: 16px;
}
.Announcement_titleImportant__Vm_Pc {
  color: var(--mantine-color-black);
}
[data-mantine-color-scheme='dark'] .Announcement_titleImportant__Vm_Pc {
  color: var(--mantine-color-white);
}
.Announcement_titleImportant__Vm_Pc {
  font-size: 20px;
}

.Announcement_link__7kwxl {
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] .Announcement_link__7kwxl {
  color: var(--mantine-color-white);
}
.Announcement_linkImportant__WM9mg {
  color: var(--mantine-color-black);
}
[data-mantine-color-scheme='dark'] .Announcement_linkImportant__WM9mg {
  color: var(--mantine-color-white);
}

.Announcement_grid__szbRr {
  display: grid;
  grid-auto-rows: max-content;
}

@media (max-width: 768px) {
  .Announcement_grid__szbRr {
    grid-template-columns: 1fr;
  }
}

